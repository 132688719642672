<template>
  <RecordViewWrapper>
    <CardToolbox>
      <SearchHeaderStyle>
        <sdPageHeader title="Accounts">
          <template #subTitle>
            <a-input
              v-model:value="searchValue"
              @change="onHandleSearch"
              placeholder="Search by Name"
            >
              <template #suffix>
                <sdFeatherIcons type="search" account="16" />
              </template>
            </a-input>
          </template>
          <template #buttons>
            <sdButton
              @click="showModal"
              class="btn-add_new"
              account="default"
              type="primary"
              key="1"
            >
              <a to="#">+ Add New</a>
            </sdButton>
          </template>
        </sdPageHeader>
      </SearchHeaderStyle>
    </CardToolbox>
    <Main>
      <a-row :gutter="15">
        <a-col class="w-100" :md="24">
          <sdCards headless>
            <div>
              <TableWrapper class="table-data-view table-responsive">
                <a-table
                  @change="onHandleTableChange"
                  :pagination="pagination"
                  :dataSource="dataSource"
                  :columns="columns"
                />
              </TableWrapper>
            </div>
          </sdCards>
        </a-col>
        <sdModal
          title="Account's information"
          :visible="localState.visible"
          :footer="null"
          :onCancel="handleCancel"
        >
          <div class="project-modal">
            <FormValidationWrap>
              <VerticalFormStyleWrap>
                <a-form
                  name="sDash_validation-form"
                  ref="formRef"
                  @finish="onSubmit"
                  :model="formState"
                  :rules="rules"
                  :layout="formState.layout"
                >
                  <a-row :gutter="25">
                    <a-col :md="24" :xs="24">
                      <a-form-item ref="name" label="Account name" name="name">
                        <a-input
                          v-model:value="formState.name"
                          placeholder="Account Name"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="24" :xs="24">
                      <a-form-item ref="iban" label="IBAN" name="iban">
                        <a-input
                          v-model:value="formState.iban"
                          placeholder="IBAN"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="12">
                      <a-form-item label="Bank" name="bankId">
                        <a-select
                          v-model:value="formState.bankId"
                          placeholder="bankId"
                        >
                          <a-select-option
                            v-for="(bank, index) in BankApi"
                            :key="index"
                            :value="bank.id"
                          >
                            {{ bank.name }}</a-select-option
                          >
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :span="12">
                      <a-form-item label="Curency" name="currencyId">
                        <a-select
                          v-model:value="formState.currencyId"
                          placeholder="currencyId"
                        >
                          <a-select-option
                            v-for="(currency, index) in CurrencyApi"
                            :key="index"
                            :value="currency.id"
                          >
                            {{ currency.name }}</a-select-option
                          >
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :md="24" :xs="24">
                      <a-form-item
                        ref="initialBalance"
                        label="Initial Balance"
                        name="initialBalance"
                      >
                        <a-input-number
                          :style="{ width: '100%' }"
                          size="small"
                          v-model:value="formState.initialBalance"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="24" :xs="24">
                      <a-form-item
                        name="dateInitialBalance"
                        label="Date Initial Balance"
                        ref="dateInitialBalance"
                      >
                        <a-date-picker
                          v-model:value="formState.dateInitialBalance"
                          :style="{ width: '100%' }"
                          :format="dateFormat"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :xs="24" :md="24">
                      <sdButton
                        htmlType="submit"
                        account="full"
                        type="primary"
                        key="submit"
                      >
                        <span v-if="!formState.editionMode">Add new</span>
                        <span v-if="formState.editionMode">Edit account</span>
                      </sdButton>
                    </a-col>
                  </a-row>
                </a-form>
              </VerticalFormStyleWrap>
            </FormValidationWrap>
          </div>
        </sdModal>
      </a-row>
    </Main>
  </RecordViewWrapper>
</template>
<script>
import {
  FormValidationWrap,
  VerticalFormStyleWrap,
} from "../forms/overview/Style";
import { Main, TableWrapper, CardToolbox, SearchHeaderStyle } from "../styled";
import { RecordViewWrapper } from "../../view/crud/axios/style";
import { useStore } from "vuex";
import { computed, onMounted, reactive, ref } from "vue";
import moment from "moment";

const dateFormat = "YYYY/MM/DD";
const columns = [
  {
    title: "Account name",
    dataIndex: "account.name",
    key: "account.name",
  },
  {
    title: "Bank",
    dataIndex: "account.bankName",
    key: "account.bankName",
  },
  {
    title: "Currency",
    dataIndex: "account.currencyName",
    key: "account.currencyName",
  },
  {
    title: "IBAN",
    dataIndex: "account.iban",
    key: "account.iban",
  },
  {
    title: "Initial Balance",
    dataIndex: "account.initialBalance",
    key: "account.initialBalance",
  },
  {
    title: "Actions",
    dataIndex: "action",
    key: "action",
    width: "90px",
  },
];

const Accounts = {
  name: "Accounts",
  components: {
    Main,
    TableWrapper,
    FormValidationWrap,
    VerticalFormStyleWrap,
    RecordViewWrapper,
    CardToolbox,
    SearchHeaderStyle,
  },
  setup() {
    const { state, dispatch } = useStore();
    const searchValue = ref("");
    const BankApi = computed(() => state.BankApi.data);
    const CurrencyApi = computed(() => state.CurrencyApi.data);
    const AccountApi = computed(() => state.AccountApi.data.data);
    var accountPagination = computed(() => state.AccountApi.data);
    const isLoading = computed(() => state.AccountApi.loading);
    var sortDirections = "ascend";

    var loading = false;
    var formState = reactive({
      layout: "vertical",
      id: 0,
      name: "",
      iban: "",
      bankId: "",
      currencyId: "",
      initialBalance: "",
      dateInitialBalance: "",
    });
    const localState = reactive({
      visible: false,
      editVisible: false,
      modalType: "primary",
      url: null,
    });
    const formRef = ref();

    const rules = {
      name: [
        {
          required: true,
          message: "Account name is required",
          trigger: "change",
        },
      ],
      iban: [
        {
          required: true,
          message: "Iban is required",
          trigger: "change",
        },
      ],
      bankId: [
        {
          required: true,
          message: "Bank is required",
          trigger: "change",
        },
      ],
      currencyId: [
        {
          required: true,
          message: "Currency is required",
          trigger: "change",
        },
      ],
      initialBalance: [
        {
          type: "number",
          required: true,
          message: "This field is required",
          trigger: "change",
        },
      ],
      dateInitialBalance: [
        {
          type: "object",

          required: true,
          message: "This field is required",
          trigger: "change",
        },
      ],
    };

    onMounted(() => {
      dispatch("currencyInfo");
      dispatch("bankInfo");
      dispatch("accountGetPagedData", pagination.value);
      localState.editionMode = false;
    });
    const onHandleTableChange = (pagination) => {
      pagination = {
        ...pagination,
        sorter: {
          order: sortDirections,
        },
        current: pagination.current,
        OrderBy: sortDirections,
        searchValue: searchValue.value,
      };
      dispatch("accountGetPagedData", pagination);
    };

    var pagination = computed(() =>
      accountPagination.value && accountPagination.value.succeeded == true
        ? {
            current: accountPagination.value.pageNumber,
            total: searchValue.value
              ? accountPagination.value.recordsFiltered
              : accountPagination.value.recordsTotal,
            pageSize: accountPagination.value.pageSize,
            OrderBy: sortDirections,
            searchValue: "",
            sorter: {
              order: sortDirections,
            },
          }
        : {
            current: 1,
            pageSize: 10,
            OrderBy: sortDirections,
            searchValue: "",
            sorter: {
              order: sortDirections,
            },
          }
    );

    const onHandleSearch = () => {
      sortDirections = "ascend";
      pagination = {
        ...pagination,
        current: 1,
        OrderBy: sortDirections,
        pageSize: 10,
        searchValue: searchValue.value,
      };

      dispatch("accountGetPagedData", pagination);
    };

    const showModal = () => {
      formState.editionMode = false;
      delete formState.id;
      formState.name = "";
      formState.iban = "";
      formState.bankId = "";
      formState.currencyId = "";
      formState.initialBalance = "";
      formState.dateInitialBalance = "";
      localState.visible = true;
    };

    const handleDelete = (id) => {
      const confirm = window.confirm("Are you sure delete this?");
      if (confirm) {
        dispatch("accountApiDataDelete", id);
      }
      return false;
    };
    const handleEdit = (account) => {
      formState.editionMode = true;
      delete formState.password;
      delete formState.confirmationPassword;
      formState.id = account.id;
      formState.name = account.name;
      formState.iban = account.iban;
      formState.bankId = account.bankId;
      formState.currencyId = account.currencyId;
      formState.initialBalance = account.initialBalance;
      formState.dateInitialBalance = moment(account.dateInitialBalance);
      localState.visible = true;
    };

    const dataSource = computed(() =>
      AccountApi.value && AccountApi.value.length
        ? AccountApi.value.map((account, key) => {
            return {
              key: key + 1,
              account,
              action: (
                <div class="table-actions">
                  <a-button class="edit" onClick={() => handleEdit(account)}>
                    <sdFeatherIcons type="edit" account={14} />
                  </a-button>
                  &nbsp;&nbsp;&nbsp;
                  <a-button
                    class="delete"
                    onClick={() => handleDelete(account.id)}
                  >
                    <sdFeatherIcons type="trash-2" account={14} />
                  </a-button>
                </div>
              ),
            };
          })
        : []
    );

    const onSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          if (!formState.editionMode) {
            dispatch("accountSubmitData", {
              formState,
            });
          } else {
            dispatch("accountUpdateData", {
              formState,
            });
          }
          onCancel();
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    const handleCancel = () => {
      onCancel();
    };

    const onCancel = () => {
      localState.visible = false;
      formState.editionMode = false;
      localState.update = {};
    };

    return {
      formRef,
      rules,
      onSubmit,
      isLoading,
      handleDelete,
      handleEdit,
      dataSource,
      columns,
      formState,
      localState,
      showModal,
      handleCancel,
      onHandleSearch,
      loading,
      onHandleTableChange,
      searchValue,
      BankApi,
      CurrencyApi,
      dateFormat,
    };
  },
};

export default Accounts;
</script>
